<template>
   <div>
    <form @submit.prevent="onSubmit">
      <vx-card>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input
              class="w-full"
              label="Title"
              v-model="form.title"
            />
            <span class="block text-sm mt-2 text-danger">{{errors.first("title")}}</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <small class="mb-1">{{ $i18n.t('Category') }}</small>
            <v-select 
              :clearable="false" 
              v-model="form.category" 
              label="name" 
              :options="categoryOptions"
            ></v-select>
            <span class="block text-sm mt-2 text-danger">{{errors.first("category_id")}}</span>
          </div>
        </div>

        <!-- <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/1 w-full">
            <label for="" class="vs-input--label">{{$i18n.t('Summary')}}</label>
            <vs-textarea
              class="w-full"
              v-model="form.summary"
              rows="2"
            />
            <span class="block text-sm mt-2 text-danger">{{errors.first("summary")}}</span>
          </div>
        </div> -->

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/1 w-full">
            <label for="" class="vs-input--label">{{$i18n.t('Content')}}</label>
            <input type="file" @change="uploadFunction" id="file" hidden accept="image/*">
            <quill-editor v-model="form.content" :options="editorOption" ref="quillEdit"></quill-editor>
            <span class="block text-sm mt-2 text-danger">{{errors.first("content")}}</span>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/1 w-full">
            <vs-input :label="$i18n.t('Attachment')" type="file" @change="handleFileChange" name="file" class="w-full mt-4" multiple/>
            <span class="block text-sm mt-2 text-danger">{{errors.first("attachment")}}</span>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full">

            <div class="flex">
              <vs-button class="mr-3 mb-2" icon-pack="feather" icon="icon-save" @click.prevent="onSubmit">{{ $i18n.t('Save') }}</vs-button>
              <vs-button type="flat" color="primary" class="mb-2" v-if="this.$route.params.id" @click="$router.push({ name: 'learning-center-knowledge-base-detail', params: { id: $route.params.id } })">{{ $i18n.t('Cancel') }}</vs-button>
              <vs-button type="flat" color="primary" class="mb-2" v-else @click="$router.push({ name: 'learning-center-knowledge-base'})">{{ $i18n.t('Cancel') }}</vs-button>
            </div>

          </div>
        </div>

      </vx-card>
    </form>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import axios from "axios";
import storage from "@/storage";

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize';

window.Quill = require('quill');
Quill.register("modules/imageResize", ImageResize);

export default {
  components: {
    vSelect,
    quillEditor,
  },
  data () {
    return {
      categoryOptions: [],
      selectedFile : '',
        editorOption: {
            // some quill options
            modules: {
              toolbar: {
                container: [
                  [{ 'size': [] }, { 'font': [] }],
                  [{ 'color': [] }, { 'background': [] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
                  ['link', 'image', 'blockquote', 'code-block']
                  ],
                handlers: {
                  image: function() {
                    document.getElementById('file').click()
                  }
                }
              },
              imageResize: {
                modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
              }
            }
          },
      form: {
        category: '',
        files: []
      }
    }
  },
  computed: {
    ...mapGetters({
      'item': 'learningCenter/showItem'
    })
  },

  methods: {
    uploadFunction(e){
      this.selectedFile = e.target.files[0];
      let formData = new FormData();
      formData.append("image", this.selectedFile);

      let settings = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }

      // Upload image to server
      axios.post('/technical/learning-center/learning-theory/media', formData, settings)
      .then(res => {
        // Set your position cursor
        const range = this.$refs.quillEdit.quill.getSelection()
        // Set image on your server to quill editor
        this.$refs.quillEdit.quill.insertEmbed(range.index , 'image', `${storage.learningCenter}${res.data.img_url}`)
      })
      .catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.msg_status,
          color:'danger'
        })
      })
    },

    handleFileChange (e) {
      e.target.files.forEach(file => this.form.files.push(file))
    },

    getOptions () {
      axios.get('/technical/learning-center/learning-category').then(res => {
          this.categoryOptions = res.data.data.knowledge_base;
      });
    },
    
    // append form data
    appendFormData (formData, obj) {
        Object.keys(obj).forEach(key => {
          formData.append(key, obj[key]);
        });
        
        this.form.files.forEach(file => {
          formData.append('attachment[]', file)
        })

        this.form.category == '' ? '' : formData.append('category_id', this.form.category.id)
        
        return formData;
    },

    onSubmit () {
      let payload = { body: new FormData() }, action;
      payload.body = this.appendFormData(payload.body, this.form);

      if (this.id) {
        payload.body.append('_method', 'PUT');
        payload.id = this.id;

        action = this.$store.dispatch("learningCenter/updateItem", payload);
      } else {
        action = this.$store.dispatch("learningCenter/storeItem", payload);
      }

      action.then((response) => {
        this.$vs.notify({
            color: 'success',
            title: 'Successful',
            text: response.data.msg_status
        })
        if (payload.id) {
          this.$router.push({name: 'learning-center-knowledge-base-detail', params: { id: this.$route.params.id }});
        } else {
          this.$router.push({name: 'learning-center-knowledge-base'});
        }
      }).catch(err => {
        if(err.data.errors) {
          this.$setErrorsFromResponse(err.data.errors);
        } else {
          this.$catchErrorResponse(err)
        }
        setTimeout(() => {
            this.$validator.errors.clear();
          }, 3000);

        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      });
    },
  },
  
  created () {
    this.getOptions();

    if (this.$route.params.id) {
      this.id = this.$secure.decrypt(this.$route.params.id);
      this.$store.dispatch('learningCenter/detailItem', { id: this.id }).then(() => {
        
        this.form.category  = this.item.category
        this.form.title     = this.item.title
        this.form.content   = this.item.content
      });
    }
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.ql-editor {
  min-height: 300px;
}
</style>
